<template>
  <div class="addDtype">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{addDtitle}}</div>
       
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addProjects-content">
           <video :src="baseUrl + activeurn" style="width:100%" controls="controls"></video>

      </div>

     

    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount, computed } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";

import { baseUrl } from "@/js/util.js";


import { AddSecurityCategory } from '@/js/safeDisclose'

export default {
  props: {
    addDfalse: Boolean,
    bonus: Number,
    sqtype: Number,
    addDtitle: String,
    editDdata: Object,
    videoType:String,
    activeurn:String
  },
  setup (props, { emit }) {
    const state = reactive({
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      value: "",
      adding: false,
      addDtitle: '',
      editid: '',
      sqtype: '',
      num: 0,
      showstyle: false,
      videoType:'',



    });
    const closefn = () => {
      emit("close");
    };

    const cdata = getStorage("bscdata");

    const methods = {

      // 图片
      beforeUpload: (file) => {

        const is1M = file.size / 1024 / 1024 < 3; // 限制小于3M

        if (!is1M) {
          ElMessage({
            showClose: true,
            message: '大小不可超过3M',
            type: "error",
          });

        }
        return is1M
      },


      // 添加项目大事记
      AddSecurityCategory: () => {
        let datas = {}
        if (state.addDtitle == '编辑交底类别') {
          datas = {
            "id": state.editid,

            "title": state.inputs1,
            "content": state.editor.txt.html(),
            type: state.sqtype,
            "createdUser": cdata.uid,
            "projectId": cdata.pid,
          }

        } else {
          datas = {
            type: state.sqtype,
            "name": state.inputs1,
            "user": cdata.uid,
            "projectID": cdata.pid,
          }



        }


        AddSecurityCategory(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addDfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });

      },

      AddExtractApplyfn: () => {

        if (!state.inputs1) {
          return
        }

        methods.AddSecurityCategory()

      },


    };

    onMounted(() => {
      console.log(props)
      console.log(baseUrl + props.activeurn);
      state.addDtitle = props.addDtitle;
      state.sqtype = props.sqtype;
      if (state.addDtitle == '编辑交底类别') {

        state.editid = props.editdata.ID;

      }


    });

    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addDtype {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 956px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      overflow: hidden;

      span {
        display: inline-block;
        width: 100px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>